@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

@font-face {
  font-family: "PolySans";
  src: url("/fonts/PolySans-Slim.woff2") format("woff2"), url("/fonts/PolySans-Slim.woff") format("woff");
  font-style: normal;
  font-weight: 300;
  font-display: swap;
}

@font-face {
  font-family: "PolySans";
  src: url("/fonts/PolySans-SlimItalic.woff2") format("woff2"),
    url("/fonts/PolySans-SlimItalic.woff") format("woff");
  font-style: italic;
  font-weight: 300;
  font-display: swap;
}

@font-face {
  font-family: "PolySans";
  src: url("/fonts/PolySans-Neutral.woff2") format("woff2"),
    url("/fonts/PolySans-Neutral.woff") format("woff");
  font-style: normal;
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: "PolySans";
  src: url("/fonts/PolySans-NeutralItalic.woff2") format("woff2"),
    url("/fonts/PolySans-NeutralItalic.woff") format("woff");
  font-style: italic;
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: "PolySans";
  src: url("/fonts/PolySans-Median.woff2") format("woff2"), url("/fonts/PolySans-Median.woff") format("woff");
  font-style: normal;
  font-weight: 500;
  font-display: swap;
}

@font-face {
  font-family: "PolySans";
  src: url("/fonts/PolySans-MedianItalic.woff2") format("woff2"),
    url("/fonts/PolySans-MedianItalic.woff") format("woff");
  font-style: italic;
  font-weight: 500;
  font-display: swap;
}

@font-face {
  font-family: "PolySans";
  src: url("/fonts/PolySans-Bulky.woff2") format("woff2"), url("/fonts/PolySans-Bulky.woff") format("woff");
  font-style: normal;
  font-weight: 700;
  font-display: swap;
}

@font-face {
  font-family: "PolySans";
  src: url("/fonts/PolySans-BulkyItalic.woff2") format("woff2"),
    url("/fonts/PolySans-BulkyItalic.woff") format("woff");
  font-style: italic;
  font-weight: 700;
  font-display: swap;
}

@font-face {
  font-family: "PolySans Mono";
  src: url("/fonts/PolySans-NeutralMono.woff2") format("woff2"),
    url("/fonts/PolySans-NeutralMono.woff") format("woff");
  font-style: normal;
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: "PolySans Mono";
  src: url("/fonts/PolySans-MedianMono.woff2") format("woff2"),
    url("/fonts/PolySans-MedianMono.woff") format("woff");
  font-style: normal;
  font-weight: 500;
  font-display: swap;
}

/* ------ */

/* Webflow Colors */
:root {
  --black: #111;
  --purple-4: #8583ff;
  --purple-5: #6763fe;
  --black-2: #111;
  --purple-2: #d0d5ff;
  --orange-5: #f58b08;
  --green-5: #173744;
  --dim-grey: #555;
  --purple-3: #acafff;
  --purple-1: #e2e5ff;
  --purple-0: #f1f2ff;
  --white-71: white;
  --dark-4: #282c3a;
  --white: white;
  --dark-6: #4c5369;
  --green-1: #d6eaf2;
  --green-2: #99c2d2;
  --white-70: rgba(255, 255, 255, 0.7);
  --dark-7: #767f9a;
  --purple-cerrone: #acafff;
  --orrange-cerrone: #ffd29b;
  --dark-2: #151720;
  --dark-grey: #222;
  --green-0: #ecf9fe;
  --green-3: #5f92a7;
  --green-4: #2a5262;
  --orange-0: #fffbf5;
  --orange-1: #ffe9cf;
  --orange-2: #ffd29b;
  --orange-3: #fabc6f;
  --orange-4: #faa53d;
}

/* Notifications backdrop filter fallback */

@supports not (backdrop-filter: blur(32px)) {
  #notifications-drawer-bg {
    background-image: linear-gradient(178.91deg, #171621 0%, #4f507e 35.35%, #8080aa 55.76%, #d0d5ff 98.13%);
  }
}

/* ------ */

/* Gradient Borders */

.gradient-hover-border-1px {
  background-clip: padding-box;
  border: solid 1px transparent;
}

.gradient-hover-border-1px:before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  margin: -1px;
  border-radius: inherit;

  @apply bg-gradient-hover;
}

.gradient-selected-border-1px {
  background-clip: padding-box;
  border: solid 1px transparent;
}

.gradient-selected-border-1px:before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  margin: -1px;
  border-radius: inherit;

  @apply bg-gradient-selected;
}

/* ------ */

@supports (-webkit-touch-callout: none) {
  .h-screen {
    height: -webkit-fill-available !important;
  }
}

button:focus {
  outline: 0;
}

body {
  font-family: "PolySans" !important;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #ffffff;
  overflow-x: hidden;

  @apply bg-dark-2;
}

/* Temporary override of tailwind container */
.container {
  max-width: min(1600px, 94.6vw) !important;
  width: 100%;
  margin-right: auto;
  margin-left: auto;
}

/* ReCaptcha V3 */

.grecaptcha-badge {
  visibility: hidden;
}

/* Crisp */

#crisp-chatbox > div > a[role="button"] {
  margin-bottom: 40px !important;
}

/* Webflow CSS overrides against tailwind base utilities */

.wf-default img,
.wf-tags img {
  height: auto;
}

.w-webflow-badge {
  display: none !important;
}

/* Like button animation */

.like-button-liked {
  content: ""; /* needed for rendering */
  position: relative;
  display: flex; /* so we can set width and height */
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  height: 0%;
  width: 0%;
  background: red;
  transition: 1s cubic-bezier(0.25, 1, 0.5, 1);
}

/* Unclaimed Bounty Border Gradient */

.unclaimed-border::before {
  content: "";
  position: absolute;
  inset: 0;
  border-radius: 6px;
  padding: 1px; /* control the border thickness */
  background: radial-gradient(
    rgba(173, 182, 255, 1),
    rgba(245, 139, 8, 1),
    rgba(118, 123, 255, 1),
    rgba(255, 210, 155, 1),
    rgba(172, 175, 255, 1)
  );
  -webkit-mask-composite: xor;
  -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  mask-composite: exclude;
  pointer-events: none;
}

/* Custom Rounded Scroll Bar */
.custom-scroll-white {
  scrollbar-width: thin;
  scrollbar-color: rgba(255, 255, 255, 0.48) rgba(255, 255, 255, 0.05);
}

.custom-scroll-white::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  background-color: rgba(255, 255, 255, 0.05);
}

.custom-scroll-white::-webkit-scrollbar-thumb {
  border-radius: 50px;
  background-color: rgba(255, 255, 255, 0.48);
}

.custom-round-scroll-white {
  scrollbar-width: thin;
  scrollbar-color: rgba(255, 255, 255, 0.48) rgba(255, 255, 255, 0.05);
}

.custom-round-scroll-white::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  border-radius: 8px;
  background-color: rgba(255, 255, 255, 0.05);
}

.custom-round-scroll-white::-webkit-scrollbar-thumb {
  border-radius: 50px;
  background-color: rgba(255, 255, 255, 0.48);
}

.hide-scroll-bar {
  scrollbar-width: none;
}
.hide-scroll-bar::-webkit-scrollbar {
  -webkit-appearance: none;
  display: none;
  opacity: 0;
}

/* Mask Image background fade */

.fade-to-r-bg {
  -webkit-mask-image: linear-gradient(to left, rgba(0, 0, 0, 0), rgba(0, 0, 0, 1) 10%);
  mask-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 1) 10%);
}

/* React Phone Number Input */

.PhoneInputCountryIcon {
  box-shadow: none !important;
  border: 1px solid #f1f2ff;
  border-radius: 4px;
  overflow: hidden;
  width: 26px !important;
  height: 18px !important;
}

.PhoneInputInput {
  outline: none;
}

/* Input Valid & Invalid */

input[type="number"]:invalid {
  color: #ff0f3a !important;
}

input:not([type="number"]):not(:placeholder-shown):invalid {
  border-color: #ff0f3a !important;
}

input:not([type="number"]):not(:-moz-placeholder):invalid {
  border-color: #ff0f3a !important;
}

input:not([type="number"]):not(:-ms-input-placeholder):invalid {
  border-color: #ff0f3a !important;
}

input.shadow-input:not([type="number"]):not(:placeholder-shown):invalid {
  box-shadow: -2px -2px 3px rgba(255, 15, 58, 0.04), inset -1px -1px 1px rgba(255, 15, 58, 0.15),
    inset -2px -2px 4px rgba(255, 15, 58, 0.15);
}

input.shadow-input:not([type="number"]):not(:-moz-placeholder):invalid {
  box-shadow: -2px -2px 3px rgba(255, 15, 58, 0.04), inset -1px -1px 1px rgba(255, 15, 58, 0.15),
    inset -2px -2px 4px rgba(255, 15, 58, 0.15);
}

input.shadow-input:not([type="number"]):not(:-ms-input-placeholder):invalid {
  box-shadow: -2px -2px 3px rgba(255, 15, 58, 0.04), inset -1px -1px 1px rgba(255, 15, 58, 0.15),
    inset -2px -2px 4px rgba(255, 15, 58, 0.15);
}

textarea.shadow-input:not([type="number"]):not(:placeholder-shown):invalid {
  box-shadow: -2px -2px 3px rgba(255, 15, 58, 0.04), inset -1px -1px 1px rgba(255, 15, 58, 0.15),
    inset -2px -2px 4px rgba(255, 15, 58, 0.15);
}

textarea.shadow-input:not([type="number"]):not(:-moz-placeholder):invalid {
  box-shadow: -2px -2px 3px rgba(255, 15, 58, 0.04), inset -1px -1px 1px rgba(255, 15, 58, 0.15),
    inset -2px -2px 4px rgba(255, 15, 58, 0.15);
}

textarea.shadow-input:not([type="number"]):not(:-ms-input-placeholder):invalid {
  box-shadow: -2px -2px 3px rgba(255, 15, 58, 0.04), inset -1px -1px 1px rgba(255, 15, 58, 0.15),
    inset -2px -2px 4px rgba(255, 15, 58, 0.15);
}

/* Remove default input number arrows */

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

/* Potential webkit fix for 100vh ? */

.h-screen-webkit {
  min-height: -webkit-fill-available;
}

/* Override tailwind img/video height utility */
img,
video {
  height: 100%;
}

.square-ratio:after {
  content: "";
  display: flex;
  padding-top: 100%;
}

.bio-ratio:after {
  content: "";
  display: flex;
  padding-top: 151%;
}

.video-ratio:after {
  content: "";
  display: flex;
  padding-top: 56.25%;
}

.featured-card-ratio:after {
  content: "";
  display: block;
  padding-top: 5%;
}

.artist-card-ratio:after {
  content: "";
  display: block;
  padding-top: 132%;
}

.credit-card-ratio:after {
  content: "";
  display: block;
  padding-top: 50%;
}

.nft-ratio:after {
  content: "";
  display: block;
  padding-top: 160%;
}

/* Date Picker overrides */

.rdp .rdp-button {
  border-radius: 4px;
}

.rdp .rdp-button:hover {
  background-color: rgb(172 175 255 / 0.5) !important;
}

/* Big Calendar Overrides */

.rbc-overlay {
  z-index: 100 !important;
}

.rbc-row-segment {
  min-height: 43px !important;
  padding: 0 4px !important;
}

.rbc-month-view,
.rbc-month-view > *,
.rbc-header {
  border: none !important;
}

.rbc-row.rbc-month-header {
  background-color: #f1f2ff !important;
}

.rbc-date-cell {
  padding-right: 0 !important;
}

/* React DatePicker Overrides */

li.react-datepicker__time-list-item--disabled {
  display: none;
}

/* Select override */

select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: transparent;
  background-image: url("/assets/icons/select-chevron-dark.svg");
  background-size: auto 35%;
  background-repeat: no-repeat;
  background-position-x: 100%;
  background-position-y: 50%;
  padding-right: 18px;
  cursor: pointer;
}

.bg-toggle-gradient {
  background: linear-gradient(180deg, #d1d5ff 0%, #ffffff 56.29%);
}

.bg-radial-referral-purple-5 {
  background: radial-gradient(286% 234% at 29.91% 21.43%, #ffffff 0%, #e2e5ff 100%);
}

.bg-radial-referral-green-5 {
  background: radial-gradient(286% 234% at 29.91% 21.43%, #ffffff 0%, #d6eaf2 100%);
}

/* Drop shadow filters */

img.drop-shadow-rarity {
  filter: drop-shadow(0px 20px 4px rgba(172, 175, 255, 0.05))
    drop-shadow(0px 20px 12px rgba(131, 138, 201, 0.25));
  -webkit-filter: drop-shadow(0px 20px 4px rgba(172, 175, 255, 0.05))
    drop-shadow(0px 20px 12px rgba(131, 138, 201, 0.25));
}

.drop-shadow-track-thumbnail {
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.02)) drop-shadow(0px 16px 16px rgba(0, 0, 0, 0.04))
    drop-shadow(0px 20px 20px rgba(0, 0, 0, 0.04));
  -webkit-filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.02)) drop-shadow(0px 16px 16px rgba(0, 0, 0, 0.04))
    drop-shadow(0px 20px 20px rgba(0, 0, 0, 0.04));
}

.drop-shadow-verified {
  filter: drop-shadow(0px 2px 2px rgba(28, 15, 125, 0.05)) drop-shadow(0px 2px 10px rgba(28, 15, 125, 0.05));
  -webkit-filter: drop-shadow(0px 2px 2px rgba(28, 15, 125, 0.05))
    drop-shadow(0px 2px 10px rgba(28, 15, 125, 0.05));
}

.drop-shadow-waitlist-thumbnail {
  filter: drop-shadow(0px 12px 12px rgba(28, 15, 125, 0.04));
  -webkit-filter: drop-shadow(0px 12px 12px rgba(28, 15, 125, 0.04));
}

/* ----- */

.img-fade {
  mask-image: linear-gradient(to bottom, rgba(0, 0, 0, 1) 70%, rgba(0, 0, 0, 0));
}

.rounded-carousel-0 {
  border-radius: 32px;
}
.rounded-carousel-1 {
  border-radius: 28px;
}
.rounded-carousel-2 {
  border-radius: 24px;
}
.rounded-carousel-3 {
  border-radius: 20px;
}
.rounded-carousel-4 {
  border-radius: 16px;
}
.rounded-carousel-5 {
  border-radius: 12px;
}
.rounded-carousel-6 {
  border-radius: 8px;
}
.rounded-carousel-7 {
  border-radius: 4px;
}

.columns-bio {
  columns: 2;
  column-gap: 70px;
}

.columns-label {
  columns: 2;
  column-gap: 130px;
}

.columns-event-text-block {
  columns: 2;
  column-gap: 10%;
}

#footer-container {
  transition: margin-bottom 0.5s;
}

/* Stripe iFrame */

.stripe-input {
  width: 100%;
  padding: 10px 0;
  font-size: 14px;
  color: #ffffff;
  margin-bottom: 30px;
  border: none;
  border-bottom: 1px solid rgba(255, 255, 255, 0.15);
  outline: none;
  background: transparent;
}

/* Coinbase Commerce */

.coinbase-commerce-iframe-container,
.coinbase-commerce-iframe-container > iframe {
  width: 100%;
  height: 60vh;
}

/* TRIX Editor */

.trix-button--icon-quote,
.trix-button--icon-heading-1,
.trix-button--icon-code,
.trix-button-group--file-tools,
.trix-button--icon-attach {
  display: none;
  opacity: 0;
  margin: 0;
  padding: 0;
}

.trix-button-group {
  border: none !important;
  margin: 0 !important;
}

.trix-button-group--text-tools {
  margin-right: 16px !important;
}

.trix-button {
  background-color: #acafff !important;
  color: #ffffff !important;
  border: none !important;
  /* height: 24px !important; */
  /* width: 24px !important; */
  padding: 12px 8px !important;
  margin-right: 8px !important;
  border-radius: 4px !important;
}

.trix-button:last-child {
  margin-right: 0 !important;
}

.trix-button-row {
  padding: 12px;
  border-bottom: 1px solid #767f9a;
}

.trix-content ul > li::before {
  content: "\2022"; /* Unicode bullet symbol */
  color: #222222; /* Bullet color */

  /* Optional tweaks */
  font-weight: bold;
  padding-right: 10px;
}

.trix-content ol li {
  counter-increment: count;
}
.trix-content ol li:before {
  content: counter(count) ". ";
  font-weight: 600;
}
.trix-content ol {
  list-style: none;
  counter-reset: count;
}
.trix-content a {
  color: #8489ff;
  text-decoration: underline;
}

.trix-content h3 {
  color: #e2e5ff;
  font-size: 18px;
  font-weight: 700;
  line-height: 125%;
  margin-top: 20px;
  margin-bottom: 20px;
}

/* Mobile/Tablets */

@media (max-width: 1023px) {
  .container {
    max-width: min(calc(100vw - 40px), 92vw) !important;
  }

  .artist-card-ratio:after {
    content: "";
    display: block;
    padding-top: 145%;
  }

  .columns-bio,
  .columns-label,
  .columns-event-text-block {
    columns: 1;
    column-gap: 0;
  }

  .rounded-carousel-0 {
    border-radius: 20px;
  }
  .rounded-carousel-1 {
    border-radius: 18px;
  }
  .rounded-carousel-2 {
    border-radius: 14px;
  }
  .rounded-carousel-3 {
    border-radius: 10px;
  }
  .rounded-carousel-4 {
    border-radius: 7px;
  }
  .rounded-carousel-5 {
    border-radius: 5.5px;
  }
  .rounded-carousel-6 {
    border-radius: 4px;
  }
  .rounded-carousel-7 {
    border-radius: 3px;
  }

  .hide-scroll-bar,
  .hide-scroll-bar-mobile {
    scrollbar-width: none;
  }
  .hide-scroll-bar::-webkit-scrollbar,
  .hide-scroll-bar-mobile::-webkit-scrollbar {
    -webkit-appearance: none;
    display: none;
    opacity: 0;
  }
}

:root {
  --toast-container-top: 112px;
  --toast-container-z-index: 999;
}

.Toastify__toast-container--top-right {
  z-index: var(--toast-container-z-index);
  top: var(--toast-container-top);
  transition: top 0.2s linear;
  padding: 0;
  right: 2.7vw;
}

.Toastify__toast-container--bottom-center {
  z-index: 999999999;
  width: auto;
  max-width: 670px;
  min-width: 400px;
}

.Toastify__toast-container--top-right .Toastify__toast {
  background: linear-gradient(0deg, rgba(118, 123, 255, 0.3) 0%, rgba(118, 123, 255, 0.3) 100%), #282c3a;
  box-shadow: 0px 2px 2px rgba(28, 15, 125, 0.02), 0px 4px 4px rgba(28, 15, 125, 0.02),
    0px 12px 12px rgba(28, 15, 125, 0.02), 0px 20px 20px rgba(28, 15, 125, 0.02);
  border-radius: 6px;
  border: 1px solid #8489ff;
  color: #fff;
  font-family: "PolySans";
  font-size: 14px;
  line-height: 120%;
  padding: 12px;
  cursor: auto;
  position: relative;
}

.Toastify__toast-container--bottom-center .Toastify__toast {
  backdrop-filter: blur(48px);
  box-shadow: 0px 16.219px 40.547px 0px rgba(0, 0, 0, 0.1);
  border-radius: 9px;
  color: #fff;
  font-family: "PolySans";
  font-size: 15px;
  line-height: 110%;
  padding: 12px;
  min-height: 40px;
  width: auto;
  cursor: auto;
}

/* Status Styles */

.Toastify__toast-container--bottom-center .Toastify__toast.Toastify__toast--error {
  border: 2px solid rgba(251, 70, 102, 0.6);
  background: linear-gradient(0deg, rgba(251, 70, 102, 0.3) 0%, rgba(251, 70, 102, 0.3) 100%), #282c3a;
}

.Toastify__toast-container--bottom-center .Toastify__toast.Toastify__toast--success {
  border: 2px solid rgba(214, 234, 242, 0.7);
  background: linear-gradient(0deg, rgba(95, 146, 167, 0.3) 0%, rgba(95, 146, 167, 0.3) 100%), #282c3a;
}

.Toastify__toast-container--bottom-center .Toastify__toast.Toastify__toast--warning {
  border: 2px solid #f58b08;
  background: linear-gradient(0deg, rgba(245, 139, 8, 0.3) 0%, rgba(245, 139, 8, 0.3) 100%), #282c3a;
}

.Toastify__toast-container--bottom-center .Toastify__toast.Toastify__toast--info {
  border: 2px solid #8489ff;
  background: linear-gradient(0deg, rgba(118, 123, 255, 0.3) 0%, rgba(118, 123, 255, 0.3) 100%), #282c3a;
}

/* ------------- */

.Toastify__toast-icon {
  width: auto;
  max-width: 40px;
}

.Toastify__toast-body {
  padding: 0;
  margin: 0;
}

.Toastify__progress-bar {
  height: 4px;
}

.Toastify__close-button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  border-radius: 100%;
  margin-left: 16px;
  margin-top: -4px;
  opacity: 1;
  flex-shrink: 0;
}

.Toastify__close-button:hover {
  background-color: rgba(34, 34, 34, 0.15);
}

.Toastify__close-button > svg {
  color: #fff;
  width: 10px;
  height: 10px;
}

@media (max-width: 1023px) {
  .Toastify__toast {
    padding: 8px;
    margin-bottom: 8px;
    font-size: 14px;
  }

  .Toastify__toast-container--bottom-center {
    min-width: 320px;
    max-width: calc(100vw - 40px);
  }
}

@media (max-width: 480px) {
  .Toastify__toast-container {
    width: 94.6vw;
    left: 2.7vw;
  }
}
